<template>
	<b-container fluid>
		<b-row>
			<b-col sm="12">
				<card>
					<template v-slot:headerTitle>
						<h4 class="card-title">Sub Branches</h4>
					</template>
					<template v-slot:headerAction>
						<div class="float-right">
							<b-button v-b-modal.sub-branch-modal variant="primary" class="btn-sm">Add Sub Branch</b-button>
						</div>
					</template>
					<template v-slot:body>
						<!----- Listing length and search starts ----->
						<div class="row listing-length-row">
							<div class="col-sm-12 col-md-6">
								<div class="listing-length" >
									<label
										>Show
										<select class="form-control" v-model="listing_length" @change="fetchSubbranches()">
											<option value="5">5</option>
											<option value="10">10</option>
											<option value="15">15</option>
										</select>
										entries
									</label>
								</div>								
							</div>
							<div class="col-sm-12 col-md-6">
								<div class="div-search">
									<label>
										<input type="text" class="form-control" placeholder="Search" v-model="keyword" @keyup="fetchSubbranches()" />
									</label>
								</div>							
								
							</div>
						</div>
						<!----- Listing length and search ends ----->

						<!--- Listing starts ----->
						<div class="table-responsive">
							<table id="datatable" class="table data-table table-striped table-bordered dataTable">
								<thead>
									<tr>
										<th width="10%">Sub Branch</th>
										<th width="10%">Branch</th>					
										<th width="15%">Created on</th>
										<th width="10%">Options</th>
									</tr>
								</thead>
								<tbody v-if="Boolean(sub_branches.length)">
									<tr v-for="sub_branch in sub_branches" :key="sub_branch.id">
										<td>{{ sub_branch.subbranch }}</td>
										<td>{{ sub_branch.branch }}</td>	
										<td>{{ sub_branch.created_at | formatDateTime }}</td>
										<td>
										  <b-button variant="primary" class="btn btn-sm mt-2 mr-1" @click="editSubBranch(sub_branch)">Edit</b-button>
										  <b-button variant="danger" class="btn mt-2 mr-1 btn-sm" @click="deactivateSubBranch(sub_branch)" v-if="sub_branch.status == 1">Unpublish</b-button>
										  <b-button variant="success" class="btn mt-2 mr-1 btn-sm" @click="activateSubBranch(sub_branch)" v-else>Publish</b-button>
										</td>
									</tr>
								</tbody>
								<tbody v-else>
									<tr>
										<td colspan="5" class="text-center">No data found!</td>
									</tr>
								</tbody>
							</table>
						</div>
						<!-- Listing ends -->
						<!---- modal starts ---->
						<b-modal ref="sub-branch-modal" id="sub-branch-modal" :title="modal_title" hide-footer @hidden="clearForm()">
							<b-form @submit.prevent="addSubBranch">
								<b-col md="12" class="form-group">
									<label class="control-label">SubBranch<span class="required">*</span></label>
									<b-form-input v-model="sub_branch.sub_branch_name" v-bind:class="{ 'is-invalid': Boolean(errors.sub_branch_name) }"></b-form-input>
									<div v-for="(val, index) in errors.sub_branch_name" :key="index" class="invalid-feedback">
										<p class="mb-0">{{ val }}</p>
									</div>
								</b-col>

								<b-col md="12" class="form-group">
									<label class="control-label">Branch<span class="required">*</span></label>									
									<b-form-select v-model="sub_branch.branch_id" v-bind:class="{ 'is-invalid': Boolean(errors.branch_id) }">
										<template #first>
											<b-form-select-option :value="null" disabled>-- Select branch --</b-form-select-option>
										</template>
										<b-form-select-option v-for="branch in branches" :key="branch.id" :value="branch.id">{{ branch.branch }}</b-form-select-option>
									</b-form-select>

									<div v-for="(val, index) in errors.branch_id" :key="index" class="invalid-feedback">
										<p class="mb-0">{{ val }}</p>
									</div>
								</b-col>
								
								<b-col md="12">
									<b-button type="submit" class="mr-3" variant="primary">{{ button_title }}</b-button>
									<b-button class="" variant="light" @click="hideModal('sub-branch-modal')">Close</b-button>
								</b-col>
							</b-form>
						</b-modal>
						<!---- modal ends ----->
						<!----pagination starts --->
						<nav aria-label="Page navigation">
							<ul class="pagination">
								<li class="page-item" v-bind:class="[{ disabled: !page.url }, { active: page.active }]" v-for="(page, index) in pagination" :key="page.label">
									<a class="page-link" href="#" @click.prevent="fetchSubbranches(page.url + '&limit=' + listing_length + '&keyword=' + keyword)" tabindex="-1">
										<span v-if="index == 0">Prev</span>
										<span v-else-if="index == pagination.length - 1">Next</span>
										<span v-else>{{ page.label }}</span>
									</a>
								</li>
							</ul>
						</nav>
						<!----pagination ends --->
					</template>
				</card>
			</b-col>
		</b-row>
	</b-container>
</template>
<script>
export default {
	name: "SubBranches",
	data() {
		return {
			sub_branches: [],
			branches: [],
			sub_branch: {
				id: null,
			   	sub_branch_name: "",
			   	branch_id: null,
				id_branch: "",
			},
			
			modal_title: "Add Sub Branch",
			button_title: "Save",
			pagination: {},
			edit: false,
			sub_branch_status: {
				id: "",
				status: "",
			},
			branch_id: null,
			id_branch: "",
			keyword: "",
			showsearch: false,
			listing_length: 10,
			status: '',
			token: "",
			errors: {},
			activate: false,
         	deactivate: false,

         	active_page_url: null,
		};
	},
	mounted() {
			
	},

	created() {
		this.fetchSubbranches();
	    this.fetchBranches();
	},

		methods: {

		    getToken() 
		    {
				if (localStorage.token) {
					this.token = localStorage.token;
				} else {
					this.$vToastify.error("Session expired, please login again");
					this.$router.push({ name: "auth.login" });
				}
			},

		    fetchSubbranches(page_url = null) 
		    {
				let url = "/api/sub_branches/index?limit=" + this.listing_length + "&keyword=" + this.keyword;
				page_url = page_url || url;
				this.active_page_url = page_url;
				this.getToken();
				this.$axios
					.get(page_url, { headers: { Authorization: "Bearer " + this.token } })
					.then((res) => {
						var responseData = res.data.data;
						this.sub_branches = responseData.data;
						this.pagination = responseData.links;
					})
					.catch((err) => console.log(err));
			},

			fetchBranches() 
			{
				this.getToken();
				this.$axios
					.get("/api/sub_branches/getBranches", {
						headers: { Authorization: "Bearer " + this.token },
					})
					.then((res) => {
						var responseData = res.data;
						this.branches    = responseData.data;
					})
					.catch((err) => console.log(err));
			},

			showModal(modal) {
				this.$refs[modal].show();
			},

			hideModal(modal) {
				this.$refs[modal].hide();
			},
            
            addSubBranch() 
            {
				this.getToken();
				let formData = new FormData();
				formData.append("sub_branch_name", this.sub_branch.sub_branch_name);
				if (this.sub_branch.branch_id) {
					this.sub_branch.id_branch = this.sub_branch.branch_id;
				} else {
					this.sub_branch.id_branch = "";
				}
				console.log(this.sub_branch.branch_id);
				formData.append("branch_id", this.sub_branch.id_branch);
				const config = {
					headers: {
						"content-type": "multipart/form-data",
						Authorization: "Bearer " + this.token,
					},
				};
				let url;
				if (!this.edit) {
					url = "/api/sub_branches/add";
					this.active_page_url = null;
				} else {
					url = "/api/sub_branches/update";
					formData.append("sub_branch_id", this.sub_branch.id);
				}
				this.$axios
					.post(url, formData, config)
					.then((response) => {
						if (response.data.status) {
							this.$vToastify.success(response.data.message);
						} else {
							this.$vToastify.error(response.data.message);
						}
						this.clearForm();
						this.hideModal("sub-branch-modal");
						this.fetchSubbranches(this.active_page_url);
					})
					.catch((error) => {
						console.log(error.response.data);
						if (error.response) {
							if (error.response.data.message) {
								this.errors = error.response.data.message;
								this.$vToastify.error(error.response.message);
							} else {
								this.$vToastify.error("Error!");
							}
						} else {
							this.$vToastify.error("Error!");
						}
					});
			},

			editSubBranch(sub_branch) {	
			    this.edit = true;
				this.sub_branch.id = sub_branch.id;
				this.sub_branch.sub_branch_name = sub_branch.subbranch;
				this.sub_branch.branch_id = sub_branch.branch_id;
				this.errors = {};			
				this.modal_title = "Edit Sub Branch";
				this.button_title = "Update";
				this.showModal("sub-branch-modal");
			},
			clearForm() {								
				this.modal_title = "Add Sub Branch";
				this.button_title = "Add";
                this.edit = false;
				this.sub_branch.sub_branch_name = "";
				this.sub_branch.branch_id = "";				
			},
			
			activateSubBranch(sub_branch) {
				this.activate = false;
		        this.$bvModal.msgBoxConfirm('Are you sure you want to publish this sub branch?', {
		            title: 'Confirm',
		            size: 'sm',
		            buttonSize: 'sm',
		            okVariant: 'danger',
		            okTitle: 'Yes',
		            cancelTitle: 'No',
		            footerClass: 'p-2',
		            hideHeaderClose: false,
		            centered: true
		        })
		        .then(value => {
		            this.activate = value
		            if(this.activate){
						this.sub_branch_status.id = sub_branch.id;
						this.sub_branch_status.status = "1";
						this.updateSubBranchStatus();
					}
		        })
		         .catch(err => {
		            console.log(err);
		        })
			},

			deactivateSubBranch(sub_branch) {
				this.deactivate = false;
		        this.$bvModal.msgBoxConfirm('Are you sure you want to unpublish this sub branch?', {
		            title: 'Confirm',
		            size: 'sm',
		            buttonSize: 'sm',
		            okVariant: 'danger',
		            okTitle: 'Yes',
		            cancelTitle: 'No',
		            footerClass: 'p-2',
		            hideHeaderClose: false,
		            centered: true
		        })
		         .then(value => {
		            this.deactivate = value
		            if(this.deactivate){
						this.sub_branch_status.id = sub_branch.id;
						this.sub_branch_status.status = "0";
						this.updateSubBranchStatus();
					}
         		})
         		.catch(err => {
           			console.log(err);
         		})
			},
			updateSubBranchStatus() {
				const config = {
					headers: {
						Authorization: "Bearer " + this.token,
					},
				};
				this.$axios
					.get("/api/sub_branches/status?sub_branch_id=" + this.sub_branch_status.id + "&status=" + this.sub_branch_status.status, config)
					.then((response) => {
						if (response.data.status) {
							this.$vToastify.success(response.data.message);
						} else {
							this.$vToastify.error(response.data.message);
						}
						this.fetchSubbranches(this.active_page_url);
					})
					.catch((error) => {
						if (error.response) {
							if (error.response.data.message) {
								this.$vToastify.error(error.response.message);
							} else {
								this.$vToastify.error("Error!");
							}
						} else {
							this.$vToastify.error("Error!");
						}
					});
			},
		},	
};
</script>
